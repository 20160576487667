<template>
  <b-dropdown
    v-if="transferEnabled && !chatEnded && ready"
    ref="transferBtn"
    dropup
    menu-class="transfer-list px-0 py-2 r-50 shadow-sm border overflow-y-auto scrollbar-slim"
    :no-caret="!takeover"
    :size="takeover ? '' : 'sm'"
    :variant="takeover ? 'danger' : 'link'"
    :disabled="busy"
    :split="takeover"
    :title="$t('vocabulary.transfer')"
    :toggle-text="takeover ? $t('vocabulary.transfer') : null"
    @show="update"
    @click="takeover ? takeoverChat() : undefined"
  >
    <template #button-content>
      <template v-if="takeover">
        {{ $t('message.overtakeChat') }}
      </template>
      <template v-else>
        <span class="sr-only">{{ $t('vocabulary.transfer') }}</span>
        <b-spinner v-if="busy" small />
        <font-awesome-icon v-else icon="share" />
      </template>
    </template>
    <b-dropdown-group
      v-if="onlineDepartments.length"
      :header="$t('message.chatModal.depsOnline')"
    >
      <b-dropdown-item
        v-for="{
          id,
          queue_size,
          online_agents,
          name,
          estimated_wait_time,
        } in onlineDepartments"
        :key="id"
        :class="{
          'bg-danger': calculateChatLoad(queue_size, online_agents) >= 5,
        }"
        @click="departmentTransfer(id)"
      >
        <div><span>{{ name }}</span></div>
        <div
          class="w-100 d-flex justify-content-between
        text-center mt-1 mb-2"
        >
          <div
            v-b-tooltip="$options.tooltipOptions"
            class="d-flex flex-column"
            :title="$t('message.chatModal.transfer.queueSize')"
          >
            <small>
              <font-awesome-icon icon="phone-volume" />
            </small>
            <small>{{ queue_size }}</small>
          </div>
          <div
            v-b-tooltip="$options.tooltipOptions"
            class="d-flex flex-column"
            :title="$t('message.chatModal.transfer.onlineAgents')"
          >
            <small>
              <font-awesome-icon icon="users" />
            </small>
            <small>{{ online_agents }}</small>
          </div>
          <div
            v-b-tooltip="$options.tooltipOptions"
            class="d-flex flex-column"
            :title="$t('message.chatModal.transfer.estQueueTime')"
          >
            <small>
              <font-awesome-icon icon="clock" />
            </small>
            <small>
              ~{{ formatNumber(estimated_wait_time / 60) }}
              {{ $t('vocabulary.minuteShort') }}</small>
          </div>
        </div>
      </b-dropdown-item>
    </b-dropdown-group>
    <b-dropdown-group
      v-if="offlineDepartments.length"
      :header="$t('message.chatModal.depsOffline')"
    >
      <b-dropdown-item
        v-for="department in offlineDepartments"
        :key="department.id"
        :title="$t('message.chatModal.depsNoOnline')"
        class="offline"
        @click="departmentTransfer(department.id)"
      >
        {{ department.name }}
      </b-dropdown-item>
    </b-dropdown-group>
    <b-dropdown-divider />
    <b-dropdown-group
      v-if="onlineAgents.length"
      :header="$t('message.chatModal.agentsOnline')"
    >
      <b-dropdown-item
        v-for="agent in onlineAgents"
        :key="agent.id"
        @click="agentTransfer(agent.id)"
      >
        {{ agent.display_name }}
      </b-dropdown-item>
    </b-dropdown-group>
    <b-dropdown-group
      v-if="awayAgents.length"
      :header="$t('message.chatModal.agentsAway')"
    >
      <b-dropdown-item
        v-for="agent in awayAgents"
        :key="agent.id"
        :title="$t('message.chatModal.agentAwayWarn')"
        class="offline"
        @click="agentTransfer(agent.id)"
      >
        {{ agent.display_name }}
      </b-dropdown-item>
    </b-dropdown-group>
  </b-dropdown>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { sortBy } from 'lodash';

import { formatNumber } from 'supwiz/supchat/generalUtils';
import { transferChat } from '@/api/apiList';
import { tooltipOptions } from '@/utils/constants';

export default {
  name: 'ChatTransfer',
  tooltipOptions,
  props: {
    chatId: {
      type: String,
      require: true,
      default: '',
    },
    transferEnabled: {
      type: Boolean,
      required: true,
    },
    takeover: {
      type: Boolean,
      default: false,
    },
    chatEnded: {
      type: Boolean,
      required: true,
    },
    tenantId: {
      type: String,
      required: true,
    },
    departmentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
      busy: false,
      now: 0,
    };
  },
  computed: {
    ...mapState('status', ['agentsInDepartments']),
    ...mapGetters('status', ['getDepartmentStatus', 'getAgentStatus']),
    ...mapGetters('systemAgents', ['systemAgents']),
    ...mapGetters('agent', { myId: 'id' }),
    ...mapGetters('departments/openingHours', ['getIsDepartmentOpen']),
    departments() {
      const departmentIds = Object.keys(this.getDepartmentStatus());
      const list = departmentIds
        .filter((id) => id.split('.')[0] === this.tenantId)
        .map((id) => ({
          id,
          ...this.getDepartmentStatus(id),
          open: this.getIsDepartmentOpen({
            tenantId: this.tenantId,
            departmentId: id,
          }),
        }))
        .filter(({ can_be_transferred_to: display, open }) => display && open);
      return sortBy(list, [(d) => `${d.name}`.toLowerCase()]);
    },
    onlineDepartments() {
      return this.departments.filter((dep) => dep.online_agents > 0);
    },
    offlineDepartments() {
      return this.departments.filter((dep) => dep.online_agents === 0);
    },
    validAgentIdsSet() {
      const tenantId = this.departmentId.split('.')[0];
      const agentIdsSet = new Set(Object.keys(this.agentsInDepartments)
        .filter((depId) => depId.startsWith(tenantId))
        .map((depId) => this.agentsInDepartments[depId])
        .flat(),
      );
      return agentIdsSet;
    },
    agents() {
      const list = this.systemAgents.map((agent) => ({
        ...agent,
        status: this.getAgentStatus(agent.id),
      })).filter((agent) => ![
        agent.id !== this.myId,
        agent.is_bot === false,
        this.validAgentIdsSet.has(agent.id),
      ].includes(false));
      return sortBy(list, [(a) => a.display_name.toLowerCase()]);
    },
    onlineAgents() {
      return this.agents.filter((agent) => agent.status === 'ON');
    },
    awayAgents() {
      return this.agents.filter((agent) => agent.status === 'AW');
    },
  },
  async created() {
    await this.update();
    this.ready = true;
  },
  methods: {
    ...mapActions('status', ['getDepartmentStatusOverview', 'getStatusOverview']),
    ...mapActions('departments/openingHours', ['ensureOpeningHours']),
    calculateChatLoad(queueLength, agentsOnline) {
      if (queueLength === 0) return 0;
      // this shouldn't ever be displayed because 0 agents would make the department offline
      if (agentsOnline === 0) return 100;
      return queueLength / agentsOnline;
    },
    formatNumber(n) {
      return formatNumber(Number(n.toFixed(2)));
    },
    async transferChatWrapper(data) {
      try {
        this.busy = true;
        await transferChat(data);
        this.$emit('transferred');
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.busy = false;
      }
    },
    async agentTransfer(dstAgentId) {
      const data = {
        chat_id: this.chatId,
        dst_agent_id: dstAgentId,
      };
      return this.transferChatWrapper(data);
    },

    async departmentTransfer(dstDepId) {
      const data = {
        chat_id: this.chatId,
        dst_dep_id: dstDepId,
      };
      return this.transferChatWrapper(data);
    },
    takeoverChat() {
      this.agentTransfer(this.myId);
      if (this.$route.name === 'incoming') return;
      this.$router.push({ name: 'incoming' });
    },
    async update() {
      this.now = Date.now();
      try {
        await Promise.all([
          this.getDepartmentStatusOverview(),
          this.getStatusOverview(),
          this.ensureOpeningHours({ tenantId: this.tenantId }),
        ]);
      } catch (error) {
        this.$log.error(error);
      }
    },
  },

};
</script>

<style>
.dropdown .transfer-list {
  max-height: 50vh;
}

.dropdown .transfer-list .offline {
  opacity: .5;
}

</style>
